import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import uploadSupportingDocumentReducer from "../routes/supportingDocuments/uploadSupportingDocumentSlice";
import noSupportingDocumentReducer from "../routes/supportingDocuments/noSupportingDocumentSlice";
import transactionPageReducer from "../routes/transactions/transactionPageSlice";
import transactionDataFormReducer from "../routes/transactions/transactionDataFormSlice";
import transactionsListReducer from "../routes/transactions/transactionsListSlice";
import driverLoginReducer from "../routes/qrCode/driverLoginSlice";
import driverPhoneAuthReducer from "../routes/qrCode/driverPhoneAuthSlice";
import vehicleLoginReducer from "../routes/qrCode/vehicleLoginSlice";
import linkQrCodeReducer from "../routes/qrCode/linkQrCodeSlice";
import driverRegisterReducer from "../routes/qrCode/driverRegisterSlice";
import menuReducer from "../routes/qrCode/menuSlice";
import displayPinCodeReducer from "../routes/qrCode/displayPinCodeSlice";
import driverKnownReducer from "../routes/qrCode/driverKnownSlice";
import updateDriverPhoneReducer from "../routes/qrCode/updateDriverPhoneSlice";
import stationsListReducer from "../routes/qrCode/stationListSlice";

export const store = configureStore({
  reducer: {
    transactionPage: transactionPageReducer,
    transactionDataForm: transactionDataFormReducer,
    transactionsList: transactionsListReducer,
    uploadSupportingDocument: uploadSupportingDocumentReducer,
    noSupportingDocument: noSupportingDocumentReducer,
    driverLogin: driverLoginReducer,
    driverPhoneAuth: driverPhoneAuthReducer,
    vehicleLogin: vehicleLoginReducer,
    linkQrCode: linkQrCodeReducer,
    driverRegister: driverRegisterReducer,
    menu: menuReducer,
    displayPinCode: displayPinCodeReducer,
    driverKnown: driverKnownReducer,
    updateDriverPhone: updateDriverPhoneReducer,
    stationList: stationsListReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
