import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import {
  GetSupportingDocumentType,
  UploadSupportingDocumentFromBackResponse,
  UploadSuportingDocumentFromBackPayload,
} from "./uploadSupportingDocumentAPI";
import axios from "axios";

export interface uploadSupportingDocumentState {
  updateSupportingDocumentStatusState: QueryStatus;
  uploadSupportingDocumentFromBackStatusState: QueryStatus;
  getSupportingDocumentStatus: QueryStatus;
  presignedUrl: string;
}

const initialState: uploadSupportingDocumentState = {
  updateSupportingDocumentStatusState: "idle",
  uploadSupportingDocumentFromBackStatusState: "idle",
  getSupportingDocumentStatus: "idle",
  presignedUrl: "",
};

export const uploadSupportingDocumentFromBackAsync = createAsyncThunk(
  "uploadSupportingDocument/uploadFromBack",
  async (payload: UploadSuportingDocumentFromBackPayload, thunkAPI) => {
    const file =
      payload.fileContent && typeof payload.fileContent !== "string"
        ? new Uint8Array(payload.fileContent)
        : payload.fileContent
          ? payload.fileContent
          : "";
    const formData = new FormData();

    formData.set("file", new Blob([file]));
    formData.set("transactionId", payload.uuid || "");
    formData.set("originalFileName", payload.originalFileName || "");

    const axios = axiosInstance();
    const response = await axios.post<UploadSupportingDocumentFromBackResponse>(
      `/driver_access/supporting_documents/upload`,
      formData,
      {
        timeout: 60000,
        onUploadProgress: payload.onUploadProgress,
      },
    );
    void thunkAPI.dispatch(
      updateSupportingDocumentStatus(response.data.supportingDocumentId),
    );
  },
);

export const updateSupportingDocumentStatus = createAsyncThunk(
  "updateSupportingDocument/call",
  async (uuid: string) => {
    const axios = axiosInstance();
    await axios.post(
      `/driver_access/supporting_documents/${uuid}/validate_status`,
    );
  },
);

export const getSupportingDocument = createAsyncThunk(
  "getSupportingDocument/call",
  async (uuid: string) => {
    const axios = axiosInstance();
    const response = await axios.post<{ presignedUrl: string }>(
      `/driver_access/supporting_documents/${uuid}/get_presigned_url`,
    );
    return response.data;
  },
);

export const supportingDocumentDownloadAsync = createAsyncThunk(
  "supportingDocumentDownload/call",
  async (supportingDocument: GetSupportingDocumentType, thunkAPI) => {
    if (supportingDocument.presignedUrl && supportingDocument.fileName) {
      const response = await axios({
        url: supportingDocument.presignedUrl,
        method: "GET",
        responseType: "blob",
      });
      const a = document.createElement("a");
      a.download = supportingDocument.fileName || "yourSupportingDocument.png";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
    void thunkAPI.dispatch(
      uploadSupportingDocumentSlice.actions.resetPresignedUrl(),
    );
  },
);

export const uploadSupportingDocumentSlice = createSlice({
  name: "upload_supporting_document",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUploadStatus(state) {
      state.uploadSupportingDocumentFromBackStatusState = "idle";
    },
    resetPresignedUrl(state) {
      state.presignedUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSupportingDocumentStatus.pending, (state) => {
        state.updateSupportingDocumentStatusState = "processing";
      })
      .addCase(updateSupportingDocumentStatus.fulfilled, (state) => {
        state.updateSupportingDocumentStatusState = "success";
      })
      .addCase(updateSupportingDocumentStatus.rejected, (state) => {
        state.updateSupportingDocumentStatusState = "failed";
      })
      .addCase(uploadSupportingDocumentFromBackAsync.pending, (state) => {
        state.uploadSupportingDocumentFromBackStatusState = "processing";
      })
      .addCase(uploadSupportingDocumentFromBackAsync.fulfilled, (state) => {
        state.uploadSupportingDocumentFromBackStatusState = "success";
      })
      .addCase(uploadSupportingDocumentFromBackAsync.rejected, (state) => {
        state.uploadSupportingDocumentFromBackStatusState = "failed";
      })
      .addCase(getSupportingDocument.pending, (state) => {
        state.getSupportingDocumentStatus = "processing";
      })
      .addCase(getSupportingDocument.fulfilled, (state, action) => {
        state.presignedUrl = action.payload.presignedUrl;
        state.getSupportingDocumentStatus = "success";
      })
      .addCase(getSupportingDocument.rejected, (state) => {
        state.getSupportingDocumentStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectUpdateSupportingDocumentState = (state: RootState) =>
  state.uploadSupportingDocument.updateSupportingDocumentStatusState;

export const selectUploadSupportingDocumentFromBackStatus = (
  state: RootState,
) => state.uploadSupportingDocument.uploadSupportingDocumentFromBackStatusState;

export const selectSupportingDocumentPresignedUrl = (state: RootState) =>
  state.uploadSupportingDocument.presignedUrl;

export default uploadSupportingDocumentSlice.reducer;
