import { RouteObject } from "react-router-dom";
import { DriverLogin } from "./qrCode/driverLogin";
import { NeedHelpPage } from "./components/NeedHelpPage";
import { DriverPhoneAuth } from "./qrCode/driverPhoneAuth";
import { VehicleLogin } from "./qrCode/vehicleLogin";
import { LinkQrCodeForm } from "./qrCode/linkQrCode";
import { DriverRegister } from "./qrCode/driverRegister";
import { Menu } from "./qrCode/menu";
import { ErrorPage } from "./components/ErrorPage";
import { DisplayPinCode } from "./qrCode/displayPinCode";
import { SupportingDocumentMenu } from "./supportingDocuments/supportingDocumentMenu";
import { UploadSupportingDocument } from "./supportingDocuments/uploadSupportingDocument";
import { NoSupportingDocument } from "./supportingDocuments/noSupportingDocument";
import { NoSupportingDocumentForm } from "./supportingDocuments/noSupportingDocumentForm";
import { TransactionDataForm } from "./transactions/transactionDataForm";
import { FuelDataForm } from "./transactions/fuelDataForm";
import { TransactionsList } from "./transactions/transactionsList";
import { DriverKnown } from "./qrCode/driverKnown";
import { UpdateDriverPhone } from "./qrCode/updateDriverPhone";
import StationList from "./qrCode/stationList";

export const routes: RouteObject[] = [
  {
    path: "/upload",
    element: <SupportingDocumentMenu />,
  },
  {
    path: "/upload_document",
    element: <UploadSupportingDocument />,
  },
  {
    path: "/no_supporting_document",
    element: <NoSupportingDocument />,
  },
  {
    path: "/no_supporting_document_form",
    element: <NoSupportingDocumentForm />,
  },
  {
    path: "/transaction",
    element: <TransactionDataForm />,
  },
  { path: "/fuel_data_form", element: <FuelDataForm /> },
  {
    path: "/transactions_list",
    element: <TransactionsList />,
  },
  {
    path: "/qr_code",
    element: <VehicleLogin />,
  },
  {
    path: "/link_qr_code",
    element: <LinkQrCodeForm />,
  },
  {
    path: "/driver_login",
    element: <DriverLogin />,
  },
  {
    path: "/driver_phone_auth",
    element: <DriverPhoneAuth />,
  },
  {
    path: "/driver_register",
    element: <DriverRegister />,
  },
  {
    path: "/menu",
    element: <Menu />,
  },
  {
    path: "/help",
    element: <NeedHelpPage />,
  },
  {
    path: "/driver_blocked",
    element: <ErrorPage page="driverBlocked" />,
  },
  {
    path: "/driver_not_found",
    element: <ErrorPage page="qrCode" />,
  },
  {
    path: "/pin_code",
    element: <DisplayPinCode />,
  },
  {
    path: "/station_list",
    element: <StationList />,
  },
  {
    path: "/driver_known",
    element: <DriverKnown />,
  },
  {
    path: "/update_driver_phone",
    element: <UpdateDriverPhone />,
  },
  {
    path: "/test_error",
    element: (
      <button
        onClick={() => {
          throw new Error("This is your first error!");
        }}
      >
        Break the world
      </button>
    ),
  },
];
