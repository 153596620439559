import styled from "styled-components";
import {
  ButtonDS,
  DriverLoadingPage,
  NearMe,
  Spacer,
  TextCapitalized,
} from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectStationList,
  selectStationListStatus,
  stationListAsync,
} from "./stationListSlice";
import { useAppDispatch } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { Page } from "../components/Page";
import { ErrorPage } from "../components/ErrorPage";

export default function StationList() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const stationList = useSelector(selectStationList);
  const stationListStatus = useSelector(selectStationListStatus);
  const [currentLocation, setCurrentLocation] = useState<{
    lon: number;
    lat: number;
  } | null>(null);
  const [hasErrorLocation, setHasErrorLocation] = useState<boolean>(false);

  useEffect(() => {
    if (!currentLocation)
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lon: position.coords.longitude,
            lat: position.coords.latitude,
          });
        },
        () => setHasErrorLocation(true),
      );
    else {
      void dispatch(
        stationListAsync({
          latitude: currentLocation.lat,
          longitude: currentLocation.lon,
        }),
      );
    }
  }, [currentLocation, dispatch]);

  if (hasErrorLocation) return <ErrorPage page="locationNotFound" />;
  if (!currentLocation?.lat && stationListStatus !== "success")
    return <DriverLoadingPage />;

  return (
    <Page
      title={t("stations.title")}
      backgroundColor="white"
      bottomButton={
        <StyledBottomButton>
          <ButtonDS
            text={t("stations.button.back")}
            buttonType="primary"
            format="hug"
            onClick={() => window.history.back()}
          />
        </StyledBottomButton>
      }
      withoutOverflowContent
    >
      <StyledColumn>
        <Spacer y={1} />
        {currentLocation &&
          stationListStatus === "success" &&
          stationList.length < 1 && (
            <TextCapitalized>{t("stations.empty.message")}</TextCapitalized>
          )}
        {stationList.map((res, i) => (
          <div key={res.uuid}>
            {i !== 0 && <Spacer y={1} />}
            <StyledStationCard>
              <Spacer x={1.5} />
              <div>
                <Spacer y={1.5} />
                <TextCapitalized>
                  <StyledCardTitle>{res.name}</StyledCardTitle>
                </TextCapitalized>
                <StyledCardSubTitle>
                  <NearMe />
                  {res.distanceInKm.toPrecision(2)} km
                </StyledCardSubTitle>
                <Spacer y={1} />
                <TextCapitalized>{res.address}</TextCapitalized>
                <TextCapitalized>{res.city}</TextCapitalized>
                <Spacer y={1.5} />
              </div>
              <Spacer x={1.5} />
            </StyledStationCard>
          </div>
        ))}
        <Spacer y={16} />
      </StyledColumn>
    </Page>
  );
}

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledStationCard = styled.div`
  display: flex;
  max-width: 100%;
  border-radius: 1.25rem;
  border: 0.0625rem solid ${colors["colors/borders/input/default"]};
`;

const StyledCardTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
`;

const StyledCardSubTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors["colors/text/lightGrey"]};
  & svg path {
    fill: ${colors["colors/text/lightGrey"]};
  }
`;

const StyledBottomButton = styled.div`
  width: 100%;
`;
