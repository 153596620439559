import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { axiosInstanceWithPhoneToken, QueryStatus } from "../../utils";
import { StationTypeApi, StationTypeDisplayed } from "./stationListApi";

export interface stationListState {
  stationListStatus: QueryStatus;
  stationList: StationTypeDisplayed[];
}

const initialState: stationListState = {
  stationList: [],
  stationListStatus: "idle",
};

export const stationListAsync = createAsyncThunk(
  "stationList/call/",
  async (payload: { latitude: number; longitude: number }) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error(); //TODO
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    const response = await axios.get<StationTypeApi[]>(
      "/driver_access/service_stations/closest",
      {
        params: payload,
      },
    );

    return response.data;
  },
);

export const stationListSlice = createSlice({
  name: "stations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(stationListAsync.pending, (state) => {
        state.stationListStatus = "processing";
      })
      .addCase(stationListAsync.fulfilled, (state, action) => {
        state.stationList = action.payload;
        state.stationListStatus = "success";
      })
      .addCase(stationListAsync.rejected, (state) => {
        state.stationListStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectStationList = (state: RootState) =>
  state.stationList.stationList;

export const selectStationListStatus = (state: RootState) =>
  state.stationList.stationListStatus;

export default stationListSlice.reducer;
