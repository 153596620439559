import { capitalize, LaunchPage } from "@qivia/ui";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectDriverPhone } from "./driverLoginSlice";
import { ErrorPage } from "../components/ErrorPage";
import {
  selectCardHolderIdentification,
  selectQrCodeId,
} from "./vehicleLoginSlice";
import TermsAndPolicySentence from "./termsAndPolicySentence";
import { DriverNameForm } from "../components/DriverNameForm";
import {
  selectUpdateDriverPhoneStatus,
  updateDriverPhoneAsync,
} from "./updateDriverPhoneSlice";
import { selectActiveDriverByPhone } from "./menuSlice";

export const UpdateDriverPhone = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const updateDriverPhoneStatus = useAppSelector(selectUpdateDriverPhoneStatus);
  const qrCodeId = useAppSelector(selectQrCodeId);
  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const driverPhone = useAppSelector(selectDriverPhone);
  const currentDriver = useAppSelector(selectActiveDriverByPhone);

  useEffect(() => {
    if (updateDriverPhoneStatus !== "success") return;
    navigate("/menu");
  }, [updateDriverPhoneStatus, navigate]);

  const updateDriverPhone = useCallback(() => {
    if (!cardHolderIdentification) return;
    if (!currentDriver.driver) return;
    void dispatch(
      updateDriverPhoneAsync({
        newDriverFirstName: capitalize(firstName),
        newDriverLastName: capitalize(lastName),
        oldDriver: currentDriver.driver.uuid,
      }),
    );
  }, [
    cardHolderIdentification,
    currentDriver.driver,
    dispatch,
    firstName,
    lastName,
  ]);

  if (
    driverPhone === null ||
    !qrCodeId ||
    !cardHolderIdentification ||
    updateDriverPhoneStatus === "failed" ||
    currentDriver.driver === null
  ) {
    return <ErrorPage page="qrCode" />;
  }
  if (updateDriverPhoneStatus === "processing") {
    return <LaunchPage />;
  }

  return (
    <DriverNameForm
      phone={driverPhone}
      firstName={firstName}
      lastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
      followingPage={updateDriverPhone}
      termsAndPolicySentence={<TermsAndPolicySentence />}
      type="UPDATE"
      currentDriver={currentDriver}
    />
  );
};
