import { capitalize, LaunchPage } from "@qivia/ui";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  driverRegisterAsync,
  selectDriverRegisterStatus,
} from "./driverRegisterSlice";
import { selectDriverPhone } from "./driverLoginSlice";
import { ErrorPage } from "../components/ErrorPage";
import {
  selectCardHolderIdentification,
  selectQrCodeId,
} from "./vehicleLoginSlice";
import TermsAndPolicySentence from "./termsAndPolicySentence";
import { DriverNameForm } from "../components/DriverNameForm";

export const DriverRegister = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const driverRegisterStatus = useAppSelector(selectDriverRegisterStatus);
  const qrCodeId = useAppSelector(selectQrCodeId);
  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const driverPhone = useAppSelector(selectDriverPhone);

  useEffect(() => {
    if (driverRegisterStatus !== "success") return;
    navigate("/menu");
  }, [driverRegisterStatus, navigate]);

  const registerDriver = useCallback(() => {
    if (!cardHolderIdentification) return;
    void dispatch(
      driverRegisterAsync({
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        cardHolderIdentification,
      }),
    );
  }, [dispatch, firstName, lastName, cardHolderIdentification]);

  if (
    driverPhone === null ||
    !qrCodeId ||
    !cardHolderIdentification ||
    driverRegisterStatus === "failed"
  ) {
    return <ErrorPage page="qrCode" />;
  }
  if (driverRegisterStatus === "processing") {
    return <LaunchPage />;
  }

  return (
    <DriverNameForm
      phone={driverPhone}
      firstName={firstName}
      lastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
      followingPage={registerDriver}
      termsAndPolicySentence={<TermsAndPolicySentence />}
      type="CREATION"
      currentDriver={null}
    />
  );
};
