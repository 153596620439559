import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { TextCapitalized } from "@qivia/ui/src/designSystem/components/text/TextCapitalized";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  TaskAltOutlined,
  NotInterestedOutlined,
  VisibilityOutlined,
  ContactSupportOutlined,
  EditOutlined,
  LocalGasStationOutlined,
} from "@qivia/ui/src/designSystem/materialUi/materialUi";
import {
  CardWithRegistrationNumberDriverApp,
  LockedLittleBlackCard,
  Lock,
} from "@qivia/ui/src/assets/assets";
import { useNavigate } from "react-router-dom";
import { Avatar, RowClickable, RowElement, RowToggle } from "@qivia/ui";
import { ActiveDriverByPhoneType, CardExpenses } from "../qrCode/menuApi";
import { useAppSelector } from "../../redux/hooks";
import { menuSlice, selectCardTemporaryEndOfUsage } from "../qrCode/menuSlice";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { selectCardHolderIdentification } from "../qrCode/vehicleLoginSlice";
import { AppDispatch } from "../../redux/store";

type PaymentStateType = "AUTHORIZED" | "UNAUTHORIZED";

export interface DriverPaymentPageProps {
  title: string;
  subTitle: string;
  isInOpposition?: boolean;
  isCardLocked?: boolean;
  state: PaymentStateType;
  changeCardStatus: (isCardLocked: boolean) => void;
  disabled: boolean;
  isRotationRequestExceeded: boolean;
  activeDriverByPhone: ActiveDriverByPhoneType;
  cardExpenses: CardExpenses | null;
}

export const DriverPaymentPage = (props: DriverPaymentPageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const temporaryEndOfUsage = useAppSelector(selectCardTemporaryEndOfUsage);
  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const [now, setNow] = useState(new Date());

  const computedRemainingTime = useMemo(
    () =>
      temporaryEndOfUsage
        ? new Date(temporaryEndOfUsage).getTime() - now.getTime()
        : null,
    [now, temporaryEndOfUsage],
  );

  const formattedRemainingTimeMessage = useMemo(() => {
    const computedRemainingTimeDate =
      computedRemainingTime && computedRemainingTime > 0
        ? new Date(computedRemainingTime)
        : null;

    return computedRemainingTimeDate
      ? `${t("driverPayment.remaining_time.text")} : ${computedRemainingTimeDate.getMinutes()}:${computedRemainingTimeDate.getSeconds()}`
      : "";
  }, [computedRemainingTime, t]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (computedRemainingTime) {
      if (computedRemainingTime > 0) {
        interval = setInterval(() => {
          setNow(new Date());
        }, 1000);
      } else {
        dispatch(menuSlice.actions.setScanTimeout());
      }

      return () => {
        clearInterval(interval);
      };
    }
    return;
  }, [cardHolderIdentification, computedRemainingTime, dispatch]);

  return (
    <StyledPageContainer>
      <StyledHeaderContainer>
        <Spacer x={1} />
        <StyledColumn>
          <StyledIcon $state={props.state}>
            {props.state === "AUTHORIZED" ? (
              <TaskAltOutlined />
            ) : (
              <NotInterestedOutlined />
            )}
          </StyledIcon>
          <Spacer y={1} />
          <StyledTitle>
            <TextCapitalized>{props.title}</TextCapitalized>
          </StyledTitle>
          <Spacer y={0.25} />
          <StyledSubTitle>
            <TextCapitalized>{props.subTitle}</TextCapitalized>
            {computedRemainingTime && (
              <TextCapitalized>{formattedRemainingTimeMessage}</TextCapitalized>
            )}
          </StyledSubTitle>
        </StyledColumn>
        <Spacer x={1} />
      </StyledHeaderContainer>
      <StyledAbsolute $isLocked={props.isCardLocked}>
        {props.isCardLocked ? (
          <LockedLittleBlackCard />
        ) : (
          <CardWithRegistrationNumberDriverApp />
        )}
      </StyledAbsolute>
      <DriverPaymentPageContent {...props} />
      <Spacer y={2} />
    </StyledPageContainer>
  );
};

const DriverPaymentPageContent = (props: DriverPaymentPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const activeDriverByPhone = props.activeDriverByPhone.driver;

  const disabledActions =
    props.isInOpposition || props.isRotationRequestExceeded;

  return (
    <StyledFlex>
      <Spacer x={1} />
      <StyledBodyContent>
        <Spacer y={11} />
        {activeDriverByPhone && (
          <>
            <RowElement
              leftIcon={
                <StyledAvatar>
                  <Avatar
                    size="M"
                    color="red"
                    firstName={activeDriverByPhone.firstName}
                    lastName={activeDriverByPhone.lastName}
                  />
                </StyledAvatar>
              }
              text={
                <StyledFlex>
                  <Spacer x={0.75} />
                  <TextCapitalized>
                    {activeDriverByPhone.firstName}
                  </TextCapitalized>
                  <Spacer x={0.25} />
                  <TextCapitalized>
                    {activeDriverByPhone.lastName}
                  </TextCapitalized>
                  <Spacer x={0.25} />
                </StyledFlex>
              }
              size="L"
              rightElement={
                disabledActions ? (
                  <></>
                ) : (
                  <StyledElement>
                    <EditOutlined
                      onClick={() => navigate("/update_driver_phone")}
                    />
                  </StyledElement>
                )
              }
            />
            <Spacer y={1} />
          </>
        )}
        {props.cardExpenses?.typeLimit && (
          <>
            <RowElement
              text={t(
                `driverPayment.cardExpenses.type.${props.cardExpenses.typeLimit}`,
              )}
              size="M"
              bottomElement={
                <StyledLine
                  $percentage={
                    props.cardExpenses.expenses
                      ? (props.cardExpenses.expenses /
                          props.cardExpenses.amountLimit) *
                        100
                      : 0
                  }
                />
              }
              rightElement={
                <StyledExpenses>
                  {props.cardExpenses.expenses} /
                  {props.cardExpenses.amountLimit}{" "}
                  {t(`driverPayment.cardExpenses.units`)}
                </StyledExpenses>
              }
            />
            <Spacer y={1} />
          </>
        )}
        {!disabledActions && (
          <>
            <RowClickable
              text={t("driverPayment.pin")}
              icon={<VisibilityOutlined />}
              onClick={() => !props.isCardLocked && navigate("/pin_code")}
            />
            <Spacer y={1} />
            <RowClickable
              text={t("driverPayment.stations")}
              icon={<LocalGasStationOutlined />}
              onClick={() => navigate("/station_list")}
            />
            <Spacer y={1} />
            <RowToggle
              onClick={() => props.changeCardStatus(!props.isCardLocked)}
              check={props.isCardLocked ?? false}
              size={"L"}
              text={t(
                `driverPayment.${props.isCardLocked ? "unlock" : "lock"}`,
              )}
              icon={<Lock />}
              disabled={props.disabled}
            />
            <Spacer y={1} />
          </>
        )}
        <RowClickable
          text={t("driverPayment.support")}
          icon={<ContactSupportOutlined />}
          onClick={() => navigate("/help")}
        />
      </StyledBodyContent>
      <Spacer x={1} />
    </StyledFlex>
  );
};

const StyledElement = styled.div`
  cursor: pointer;
`;

const StyledLine = styled.div<{ $percentage: number }>`
  width: 100%;
  border-bottom: 0.25rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 2rem;
  border-image: linear-gradient(
      to right,
      ${colors["colors/accent/500"]} ${({ $percentage }) => $percentage}%,
      ${colors["colors/borders/button/primary"]}
        ${({ $percentage }) => $percentage}%
    )
    5;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledAvatar = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  align-items: center;
`;

const StyledExpenses = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 10rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBodyContent = styled.div` 
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  ${typographies["Body/M"]}]};
  color: ${colors["colors/text/black"]};
 
`;

const StyledIcon = styled.div<{
  $state: string;
}>`
  & svg {
    width: 3.32rem;
    height: 3.32rem;
    fill: ${({ $state }) =>
      $state === "AUTHORIZED"
        ? colors["colors/accent/600"]
        : colors["colors/system/error/error_normal"]};
  }
  display: flex;
`;

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${colors["colors/text/white"]};
`; //no typo

const StyledSubTitle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors["colors/text/white"]};
  text-align: center;
`; //no typo

const StyledAbsolute = styled.div<{
  $isLocked?: boolean;
}>`
  position: absolute;
  z-index: 1;
  background-color: transparent;
  justify-content: center;
  display: flex;
  width: 100%;
  padding-top: ${({ $isLocked }) => ($isLocked ? "12.5rem" : "10rem")};
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 15.5rem;
  justify-content: center;
  align-items: center;
  background: linear-gradient(203deg, #1a353a 13.88%, #244841 101.6%);
`;
